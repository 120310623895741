<script setup>
/* eslint-disable */
import { ref, watch, onMounted } from "vue";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { Turkish } from "flatpickr/dist/l10n/tr"
// import Swal from "sweetalert2";
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import i18n from "../../i18n";
import store from "@/state/store";
import axios from "axios";

import animationData from "@/components/widgets/msoeawqm.json";
import Lottie from "@/components/widgets/lottie.vue";

const { t } = i18n.global;
const title = t("Belgeler");

const items = [
  {
    text: "Elektro Portal",
    href: "/",
  },
  {
    text: t("Belgeler"),
    active: true,
  },
];


const allDocs = ref([])
const renderDocs = ref([])

const page = ref(1);
const perPage = ref(8);
const pageCount = ref(1);
const countDoc = ref([0, 0, 0])

const paginate = function(Data) {
    let xPage = page.value;
    let xPerPage = perPage.value;
    let from = xPage * xPerPage - xPerPage;
    let to = xPage * xPerPage;
    return Data.slice(from, to);
};

let NowDate = new Date()
let d2 = NowDate.toLocaleDateString("tr-TR")
NowDate.setDate(NowDate.getDate() - 365)
let d1 = NowDate.toLocaleDateString("tr-TR")

const date = ref(d1 + ' - ' + d2);
const lastDate = ref(date.value)
const date1 = ref(d1);
const date2 = ref(d2);

const statuscategory = ref('All');
const searchQuery = ref(null);
const config = ref({
    wrap: true, // set wrap to true only when using 'input-group'
    altFormat: "d.m.Y",
    altInput: true,
    dateFormat: "d.m.Y",
    mode: "range",
    locale: Turkish
})

const isStatus = ref(null);
const isPayment = ref(null);


const changecategory = function(data) {
    statuscategory.value = data;
}

const renderStatus = function(status, query = ""){ 
  query = query.toLowerCase();

  if(status != 'All'){
    renderDocs.value = allDocs.value.filter((item) => item.status == status)
  } else {
    renderDocs.value = allDocs.value
  }
  renderDocs.value = renderDocs.value.filter(
    (data) => {
        return (
          (
            data.typeText.toLowerCase().includes(query) ||
            data.docnum.toLowerCase().includes(query) ||
            query == ""
          )
        );
    }
  )
}

let formData = new FormData();
let custNo =  store.getters['userAuth/getUserData']['custNo']
formData.append('customerID', custNo)
formData.append('startDate', date1.value)
formData.append('endDate', date2.value)

const searchData = function(x=false){
  if(lastDate.value != date.value || x){
    lastDate.value = date.value

    date1.value = date.value.split(" - ")[0]
    date2.value = date.value.split(" - ")[1]

    formData.set('startDate', date1.value)
    formData.set('endDate', date2.value)

    axios.post(process.env.VUE_APP_B2B_API_URL+'doc/getDocs', formData).then((data) => {
        if(data.data.status){
          console.log(data.data.docs)
          allDocs.value = data.data.docs
          countDoc.value = data.data.count
          console.log('Çalıştı !!!')
        } else {
          allDocs.value = []
        }
        renderStatus(statuscategory.value);
    }).catch((error) => {
      console.log('Bir hata oluştu')
      console.log(error)
    })
  }
}

watch(statuscategory, (status) => {  
  renderStatus(status);
  page.value = 1
})
watch(renderDocs, (status) => {  
  pageCount.value = Math.ceil(status.length / perPage.value);
})

watch(searchQuery, (sQuery) => {
  let query = sQuery.toLowerCase();
  renderStatus(statuscategory.value, query);
})

onMounted(() => {
  searchData(true)
})

</script>
<template>
<Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
        <div class="col-lg-12">
        <div class="card" id="orderList">
          <div class="card-header border-0">
            <div class="d-flex align-items-center">
              <h5 class="card-title mb-0 flex-grow-1">Belgeler</h5>
            </div>
          </div>
          <div class="card-body border border-dashed border-end-0 border-start-0">
            <form>
              <div class="row g-3">
                <div class="col-xxl-5 col-sm-6">
                  <div class="search-box">
                    <input type="text" class="form-control search"
                        v-model="searchQuery"
                      placeholder="Belge Ara" />
                    <i class="ri-search-line search-icon"></i>
                  </div>
                </div>
                <!--end col-->
                <div class="col-xxl-2 col-sm-6">
                  <div>
                    <flat-pickr placeholder="Tarih" v-model="date" :config="config"
                      class="form-control flatpickr-input"></flat-pickr>
                  </div>
                </div>
                <!--end col-->
                <div class="col-xxl-2 col-sm-4">
                  <div>
                    <Multiselect class="form-control" placeholder="Durum" v-model="isStatus" :close-on-select="true" :searchable="true"
                      :create-option="true" :options="[
                        { value: 'All', label: 'Tümü' },
                        { value: 'Pending', label: 'Pending' },
                        { value: 'Inprogress', label: 'Inprogress' },
                        { value: 'Cancelled', label: 'Cancelled' },
                        { value: 'Pickups', label: 'Pickups' },
                        { value: 'Returns', label: 'Returns' },
                        { value: 'OFF', label: 'Kapalı' },
                      ]" />
                  </div>
                </div>
                <!--end col-->
                <div class="col-xxl-2 col-sm-4">
                  <div>
                    <Multiselect class="form-control" v-model="isPayment" :close-on-select="true" :searchable="true"
                      :create-option="true" :options="[
                        { value: '', label: 'Select Payment' },
                        { value: 'All', label: 'All' },
                        { value: 'Mastercard', label: 'Mastercard' },
                        { value: 'Paypal', label: 'Paypal' },
                        { value: 'Visa', label: 'Visa' },
                        { value: 'COD', label: 'COD' },
                      ]" />
                  </div>
                </div>
                <!--end col-->
                <div class="col-xxl-1 col-sm-4">
                  <div>
                    <button type="button" class="btn btn-primary w-100" @click="searchData">
                      <i class="ri-equalizer-fill me-1 align-bottom"></i>
                      Filtrele
                    </button>
                  </div>
                </div>
                <!--end col-->
              </div>
              <!--end row-->
            </form>
          </div>
          <div class="card-body pt-0">
            <div>
              <ul class="nav nav-tabs nav-tabs-custom nav-success mb-3" role="tablist">
                <li class="nav-item">
                  <a class="nav-link active All py-3" data-bs-toggle="tab" id="All" href="#all" role="tab"
                    @click="changecategory('All')" aria-selected="true">
                    <i class="ri-folders-fill me-1 align-bottom"></i> All Orders
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link py-3 Delivered" data-bs-toggle="tab" id="Açık" href="#open" role="tab"
                    @click="changecategory('0')" aria-selected="false">
                    <i class="ri-checkbox-circle-line me-1 align-bottom"></i>
                    Açık
                    <span class="badge bg-success align-middle ms-1" v-if="countDoc[0] > 0">{{ countDoc[0] }}</span>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link py-3 Pickups" data-bs-toggle="tab" id="Pickups" href="#partial" role="tab"
                    @click="changecategory('1')" aria-selected="false">
                    <i class="ri-truck-line me-1 align-bottom"></i> Kısmi
                    <span class="badge bg-warning align-middle ms-1" v-if="countDoc[1] > 0">{{ countDoc[1] }}</span>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link py-3 Returns" data-bs-toggle="tab" id="Returns" href="#close" role="tab"
                    @click="changecategory('2')" aria-selected="false">
                    <i class="ri-close-circle-line me-1 align-bottom"></i>
                    Kapalı
                    <span class="badge bg-danger align-middle ms-1" v-if="countDoc[2] > 0">{{ countDoc[2] }}</span>
                  </a>
                </li>
              </ul>

              <div class="table-responsive table-card mb-1">
                <table class="table table-nowrap align-middle" id="orderTable" v-if="renderDocs.length > 0">
                  <thead class="text-muted table-light">
                    <tr class="text-uppercase text-center">
                      <th class="sort" data-sort="doc_id">Belge Nu.</th>
                      <th class="sort" data-sort="doc_type">Belge Tipi</th>
                      <th class="sort" data-sort="date">Belge Tarihi</th>
                      <th class="sort" data-sort="amount">Tutar</th>
                      <th class="sort" data-sort="payment">Ödeme Yöntemi</th>
                      <th class="sort" data-sort="status">Belge Durumu</th>
                      <th class="sort" data-sort="city">İşlem</th>
                    </tr>
                  </thead>
                  <tbody class="list form-check-all" v-for="(data, index) of paginate(renderDocs)" :key="index">

                    <tr class="text-center" v-if="statuscategory=='All' || statuscategory==data.status">
                      <!-- <div v-if="statuscategory=='All' || statuscategory==data.status"> -->
                      <td class="doc_id">
                        <!-- <router-link to="/ecommerce/order-details" class="fw-medium link-primary"> -->
                          #{{ data.docnum }}
                        <!-- </router-link> -->
                      </td>
                      <td class="doc_type">{{ data.typeText }}</td>
                      <td class="date">
                        {{ new Date(data.validfrom).toLocaleDateString("tr-TR") }}
                      </td>
                      <td class="amount">{{ $filters.currency(data.grandtotal) }}</td>
                      <td class="payment">{{ data.paymtype }}</td>
                      <td class="status">
                        <span class="badge text-uppercase" :class="data.statusColor">{{ data.statusText }}</span>

                      </td>
                      <td>
                        <ul class="list-inline hstack gap-2 mb-0 d-flex justify-content-center">
                          <li class="list-inline-item" data-bs-toggle="tooltip" data-bs-trigger="hover"
                            data-bs-placement="top" title="View">
                             <router-link :to="{name: 'belge', params: { 'docType' : data.doctype, 'docNum': data.docnum }}" class="text-primary d-inline-block">
                              <i class="ri-eye-fill fs-16"></i>
                             </router-link>
                          </li>
                        </ul>
                      </td>
                      <!-- </div> -->
                    </tr>
                  </tbody>
                </table>
                <div class="noresult" v-else>
                  <div class="text-center">
                    <lottie class="avatar-xl" colors="primary:#121331,secondary:#08a88a" :options="{animationData}"
                      :height="75" :width="75" />
                    <h5 class="mt-2">Afedersiniz! Sonuç bulunamadı</h5>
                    <p class="text-muted">
                      Belirtilen kriterlere uygun belge bulanamadı.
                    </p>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-end mt-3">
                <div class="pagination-wrap hstack gap-2">
                  <a class="page-item pagination-prev disabled" href="#" v-if="page != 1" @click="page--">
                    Önceki
                  </a>
                  <ul class="pagination listjs-pagination mb-0">
                    <li v-for="pageNumber in pageCount" :key="index" @click="page = pageNumber" :class="{
                              active: pageNumber == page,
                              disabled: pageNumber == '...',
                            }">
                      <a class="page" href="#">{{ pageNumber }}</a>
                    </li>
                  </ul>
                  <a class="page-item pagination-next" href="#" @click="page++" v-if="page < pageCount">
                    İleri
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</Layout>
</template>